// src/features/auth/AuthHero.tsx
import React, { useState } from 'react';
import { DotPattern } from '@components';
import { cn } from '@/lib/utils';
import { WordRotate } from '@/components/ui/WordRotate.component';
import { WORDS_FOR_ROTATION } from '../auth.constants';
import worship from '../../../assets/worship.jpg';

export const AuthHero: React.FC = () => {
  const [heroPosition, setHeroPosition] = useState(200);

  return (
    <div className="hidden h-screen w-full object-cover lg:block relative bg-gray-900">
      <div
        style={{ backgroundImage: `url(${worship})` }}
        className={`w-full h-full absolute bg-cover bg-center opacity-50`}
      />
      <div className="-mt-10 relative items-center justify-start flex w-full h-full">
        <div
          id="word-ration-animate"
          className={`transition ease-out duration-500 relative z-10 left-[50%] whitespace-pre-wrap text-center text-4xl font-medium tracking-tighter text-white text-inline`}
          style={{ transform: `translateX(-${heroPosition}px)` }}
        >
          <strong>Tudo</strong> para{' '}
          <WordRotate
            onWordChange={(position) => setHeroPosition(position)}
            words={WORDS_FOR_ROTATION}
          />
        </div>
        <DotPattern
          className={cn(
            '[mask-image:radial-gradient(300px_circle_at_center,white,transparent)]'
          )}
        />
      </div>
    </div>
  );
};
