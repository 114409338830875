import { useEffect, useState } from 'react';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

import { cn } from '@/lib/utils';

interface WordRotateProps {
  words: { element: React.ReactNode; position: number }[];
  duration?: number;
  framerProps?: HTMLMotionProps<'h1'>;
  className?: string;
  onWordChange?: (word: number) => void;
}

export const WordRotate = ({
  words,
  duration = 2500,
  framerProps = {
    initial: { opacity: 0, y: -50 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
    transition: { duration: 0.25, ease: 'easeOut' },
  },
  className,
  onWordChange,
}: WordRotateProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, duration);

    // Call onWordChange when word changes
    onWordChange?.(words[index].position);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [words, duration, onWordChange, index]);

  return (
    <div
      className="overflow-hidden py-2 inline-block relative"
      style={{ top: 15 }}
    >
      <AnimatePresence mode="wait">
        <motion.h1 key={index} className={cn(className)} {...framerProps}>
          {words[index].element}
        </motion.h1>
      </AnimatePresence>
    </div>
  );
};
