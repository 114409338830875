// AuthRoutes.tsx
import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login } from './Login.component';
import { Signup } from './Signup.component';
import { RootState } from '../../../app/store';

export const AuthRoutes: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  if (
    profile &&
    (location.pathname === '/login' || location.pathname === '/signup')
  ) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
};
