import React from 'react';
import { EmptySection } from './EmptySection.component';
import { TicketIcon } from '@heroicons/react/24/outline';
import { EmptySectionBackground } from './EmptySectionBackground.component';

export const EmptyEvents = () => (
  <EmptySection
    title="Você ainda não tem nenhum evento"
    description="Crie seu primeiro evento agora mesmo e comece a gerenciar suas atividades"
    primaryButton={{
      label: 'Criar evento',
      onClick: () => console.log('Create event'),
    }}
    secondaryButton={{
      label: 'Saiba mais',
      onClick: () => console.log('Saiba mais'),
    }}
  >
    <div className="flex justify-center items-center relative p-2 mb-14 mt-8 bg-gray-100 w-10 h-10 rounded-md border border-gray-300">
      <EmptySectionBackground />
      <TicketIcon className="h-12 w-12 text-gray-600" />
    </div>
  </EmptySection>
);
