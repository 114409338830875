// src/app/store.ts
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/auth.slice';
import ministryReducer from '../features/ministry/ministry.slice';

// Combine the reducers
const rootReducer = combineReducers({
  auth: authReducer,
  ministry: ministryReducer,
});

// Persist configuration
const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only persist the auth slice
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Customize middleware to ignore non-serializable actions from redux-persist
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    // Ignore these action types from redux-persist
    ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
    // Optionally, you can also ignore certain paths in the state
    ignoredPaths: ['register', 'rehydrate'],
  },
});

// Configure the store with the persisted reducer and customized middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export const persistor = persistStore(store);

// Infer RootState and AppDispatch types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
