import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from '@material-tailwind/react';
import {
  TicketIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/solid';
import { logout } from '@features/auth/auth.slice';
import { useDispatch } from '@hooks/redux.hook';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to check if a route is active
  const isActive = (path: string) => location.pathname === path;

  // Function to handle navigation
  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <aside className="h-[calc(100vh-2rem)] w-full max-w-[17rem] py-4 px-2">
      <div className="flex items-center gap-2 pb-2 pt-4 px-4">
        <img
          src="https://docs.material-tailwind.com/img/logo-ct-dark.png"
          alt="brand"
          className="h-6 w-6"
        />
        <Typography variant="h5" className="text-gray-900">
          Ministry Hub
        </Typography>
      </div>
      <List>
        <hr className="my-2 border-gray-300" />
        <ListItem
          selected={isActive('/dashboard/events')}
          onClick={() => handleNavigation('/dashboard/events')}
          className="text-gray-900"
        >
          <ListItemPrefix>
            <TicketIcon className="h-5 w-5" />
          </ListItemPrefix>
          Eventos
        </ListItem>
        <ListItem
          selected={isActive('/dashboard/calendar')}
          onClick={() => handleNavigation('/dashboard/calendar')}
          className="text-gray-900"
        >
          <ListItemPrefix>
            <CalendarDaysIcon className="h-5 w-5" />
          </ListItemPrefix>
          Agenda
        </ListItem>
        <hr className="my-2 border-gray-300" />
        <ListItem className="text-gray-900">
          <ListItemPrefix>
            <InboxIcon className="h-5 w-5" />
          </ListItemPrefix>
          Notificações
          <ListItemSuffix>
            <Chip
              value="14"
              size="sm"
              variant="ghost"
              color="red"
              className="rounded-full"
            />
          </ListItemSuffix>
        </ListItem>
        <ListItem
          selected={isActive('/dashboard/profile')}
          onClick={() => handleNavigation('/dashboard/profile')}
          className="text-gray-900"
        >
          <ListItemPrefix>
            <UserCircleIcon className="h-5 w-5" />
          </ListItemPrefix>
          Perfil
        </ListItem>
        <ListItem
          selected={isActive('/dashboard/settings')}
          onClick={() => handleNavigation('/dashboard/settings')}
          className="text-gray-900"
        >
          <ListItemPrefix>
            <Cog6ToothIcon className="h-5 w-5" />
          </ListItemPrefix>
          Configuração
        </ListItem>
        <ListItem onClick={() => handleLogout()} className="text-gray-900">
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Sair
        </ListItem>
      </List>
    </aside>
  );
};
