import * as Yup from 'yup';

export const email = Yup.string()
  .email('Email inválido')
  .required('Email é obrigatório');

export const password = Yup.string()
  .min(6, 'Senha deve ter no mínimo 6 caracteres')
  .required('Senha é obrigatória');

export const name = Yup.string().required('Nome é obrigatório');
