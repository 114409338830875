import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ title, subtitle }) => (
  <div className="rounded-md border border-gray-200 bg-white p-3 shadow-md font-sans">
    <h3 className="font-semibold text-sm text-gray-900">{title}</h3>
    {subtitle && <p className="text-sm text-gray-700">{subtitle}</p>}
  </div>
);

const config: ToastOptions = {
  className: 'bg-transparent shadow-none p-0 mb-1',
  bodyClassName: '',
  closeButton: false,
  hideProgressBar: true,
};

export const error = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => toast.error(<Toast title={title} subtitle={subtitle} />, config);
