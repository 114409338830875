import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { NavigateWithQuery } from './NavigateWithQuery.component';

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { profile } = useSelector((state: RootState) => state.auth);

  return profile ? children : <NavigateWithQuery to={`/login`} />;
};
