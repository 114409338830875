import { Card as MaterialCard } from '@material-tailwind/react';
import React from 'react';

export const Card: React.FC<
  React.PropsWithChildren<{ className?: string; shadow?: boolean }>
> = ({ children, className, shadow = false }) => {
  return (
    <MaterialCard
      className={`border border-gray-300 overflow-hidden ${className}`}
      shadow={shadow}
    >
      {children}
    </MaterialCard>
  );
};
