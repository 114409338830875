import React from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

export const EmptySectionBackground = () => {
  const circles = new Array(5).fill(1);

  return (
    <div className="">
      {circles.map((_, idx) => (
        <Circle
          style={{
            height: `${(idx + 1) * 5}rem`,
            width: `${(idx + 1) * 5}rem`,
            border: `1px solid rgba(240, 240, 240, ${1 - (idx + 1) * 0.1})`,
          }}
          key={`motion-${idx}`}
          idx={idx}
        />
      ))}
    </div>
  );
};

export const Circle = ({ className, children, idx, ...rest }: any) => {
  return (
    <motion.div
      {...rest}
      initial={{
        opacity: 0,
        scale: 0.7,
        translateX: '-50%',
        translateY: '-50%',
      }}
      animate={{
        opacity: 1,
        scale: 1,
        translateX: '-50%',
        translateY: '-50%',
      }}
      transition={{
        delay: 0.3 + idx * 0.1,
        duration: 0.3,
      }}
      className={twMerge(
        'absolute inset-0 left-1/2 top-1/2 h-10 w-10 transform rounded-full border border-neutral-200',
        className
      )}
    >
      {children}
    </motion.div>
  );
};
