import { useEffect, useRef, useState } from 'react';
import {
  AnimatePresence,
  motion,
  useInView,
  UseInViewOptions,
  Variants,
} from 'framer-motion';

type MarginType = UseInViewOptions['margin'];

interface BlurFadeProps {
  children: React.ReactNode;
  className?: string;
  variant?: {
    hidden: { y: number };
    visible: { y: number };
  };
  duration?: number;
  delay?: number;
  yOffset?: number;
  inView?: boolean;
  inViewMargin?: MarginType;
  blur?: string;
  isVisible?: boolean;
}

export function BlurFade({
  children,
  className,
  variant,
  duration = 0.25,
  delay = 0,
  yOffset = 6,
  inView = false,
  isVisible,
  inViewMargin = '-50px',
  blur = '6px',
}: BlurFadeProps) {
  const ref = useRef(null);
  const inViewResult = useInView(ref, { once: true, margin: inViewMargin });
  const show = (inView && inViewResult) || isVisible;
  const [isMounted, setIsMounted] = useState(show);

  const defaultVariants: Variants = {
    hidden: { y: yOffset, opacity: 0, filter: `blur(${blur})` },
    visible: { y: 0, opacity: 1, filter: `blur(0px)` },
  };
  const combinedVariants = variant || defaultVariants;

  // Control mounting based on visibility
  useEffect(() => {
    if (show) setIsMounted(true);
  }, [show]);

  return (
    <AnimatePresence>
      {isMounted && (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={show ? 'visible' : 'hidden'}
          exit="hidden"
          variants={combinedVariants}
          transition={{
            delay: 0.04 + delay,
            duration,
            ease: 'easeOut',
          }}
          onAnimationComplete={(definition) => {
            if (definition === 'hidden') setIsMounted(false);
          }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
