import React from 'react';
import { useSelector } from 'react-redux';
import { login, loginWithGoogle } from '../auth.slice';
import { RootState } from '@store';
import { Typography } from '@material-tailwind/react';
import { Input, Button } from '@components';
import { LoadingStatus, SignInMethod } from '../auth.interface';
import { AuthHero } from '../components/AuthHero.component';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as validations from '@/utils/validations.util';
import google from '../../../assets/google.svg';
import { useDispatch } from '@hooks/redux.hook';
import { BlurFade } from '@/components/ui/BlurFade.component';

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { status, signInMethod } = useSelector(
    (state: RootState) => state.auth
  );

  const validationSchema = Yup.object({
    email: validations.email,
    password: validations.password,
  });

  const handleSubmit = (values: { email: string; password: string }) => {
    dispatch(login(values));
  };

  const handleGoogleLogin = () => {
    dispatch(loginWithGoogle());
  };

  return (
    <section className="grid relative h-screen items-center lg:grid-cols-2">
      <AuthHero />

      <div className="my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
        <BlurFade isVisible delay={0.2}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[24rem] text-left" noValidate>
                <Button
                  color="white"
                  size="lg"
                  className="mb-8 flex h-12 items-center justify-center gap-4 text-gray-900"
                  fullWidth
                  onClickCapture={handleGoogleLogin}
                  loading={
                    status === LoadingStatus.LOADING &&
                    signInMethod === SignInMethod.GOOGLE
                  }
                >
                  <img src={google} alt="google" className="h-5 w-5" /> Entre
                  com o Google
                </Button>

                <div className="my-8 flex w-full items-center gap-2">
                  <hr className="w-full bg-gray-200" />
                  <Typography color="gray" className="font-medium opacity-70">
                    ou
                  </Typography>
                  <hr className="w-full bg-blue-gray-50" />
                </div>

                <div className="mb-6">
                  <label htmlFor="email">
                    <Typography
                      variant="small"
                      className="mb-2 block font-medium text-gray-900"
                    >
                      Seu Email
                    </Typography>
                  </label>

                  <Field name="email">
                    {({ field, meta }) => (
                      <Input
                        {...field}
                        type="email"
                        placeholder="nome@email.com"
                        error={meta.touched && meta.error ? meta.error : null}
                      />
                    )}
                  </Field>

                  <Typography variant="small" color="red">
                    <ErrorMessage name="email" />
                  </Typography>
                </div>

                <div className="mb-6">
                  <label htmlFor="password">
                    <Typography
                      variant="small"
                      className="mb-2 block font-medium text-gray-900"
                    >
                      Senha
                    </Typography>
                  </label>

                  <Field name="password">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        type="password"
                        placeholder="********"
                        error={meta.touched && meta.error ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Typography variant="small" color="red">
                    <ErrorMessage name="password" />
                  </Typography>
                  <Typography
                    as="a"
                    href="#"
                    color="gray"
                    className="font-medium mt-2 w-full text-right"
                  >
                    Esqueci minha senha
                  </Typography>
                </div>

                <Button
                  type="submit"
                  color="gray"
                  className="mt-6"
                  size="lg"
                  fullWidth
                  loading={
                    status === 'loading' && signInMethod === SignInMethod.EMAIL
                  }
                  disabled={isSubmitting}
                >
                  Entrar
                </Button>

                <Typography
                  color="gray"
                  className="font-medium opacity-70 mt-6 text-center"
                >
                  Não tem uma conta?{' '}
                  <a href="/signup" className="font-medium text-gray-900">
                    Crie aqui
                  </a>
                </Typography>
              </Form>
            )}
          </Formik>
        </BlurFade>
      </div>
    </section>
  );
};
