import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

interface NavigateWithQueryProps extends NavigateProps {
  to: string;
}

export const NavigateWithQuery: React.FC<NavigateWithQueryProps> = ({
  to,
  ...props
}) => {
  const location = useLocation();

  return (
    <Navigate
      {...props}
      to={`${to}${location.hash}${location.search}`}
      replace
    />
  );
};
