import { useEffect } from 'react';

const useTabVisibilityChange = ({ onActive, onInactive }) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        onActive && onActive();
      } else if (document.visibilityState === 'hidden') {
        onInactive && onInactive();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onActive, onInactive]);
};

export default useTabVisibilityChange;
