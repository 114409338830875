import {
  createClient,
  AuthResponse,
  UserResponse,
  OAuthResponse,
  AuthChangeEvent,
  AuthSession,
} from '@supabase/supabase-js';
import { SessionResponse as CustomSessionResponse } from './supabase.interface';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL as string;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    detectSessionInUrl: true,
  },
});

const isProduction = process.env.NODE_ENV === 'production';

const supabaseAuth = {
  signIn: (email: string, password: string): Promise<AuthResponse> =>
    supabase.auth.signInWithPassword({ email, password }),
  signUp: (
    email: string,
    password: string,
    name: string
  ): Promise<AuthResponse> =>
    supabase.auth.signUp({
      email,
      password,
      options: { data: { full_name: name } },
    }),
  signOut: (): Promise<{ error: Error | null }> => supabase.auth.signOut(),
  getUser: (): Promise<UserResponse> => supabase.auth.getUser(),
  getSession: (): Promise<CustomSessionResponse> => supabase.auth.getSession(),
  signInWithGoogle: (): Promise<OAuthResponse> =>
    supabase.auth.signInWithOAuth({
      provider: 'google',
      options: { redirectTo: isProduction ? 'https://www.ministryhub.com.br/login' : 'http://localhost:3000/login' },
    }),
  onAuthStateChange: (
    callback: (event: AuthChangeEvent, session: AuthSession) => void
  ) => supabase.auth.onAuthStateChange(callback),
};

export default supabaseAuth;
