import { CardBody, Typography } from '@material-tailwind/react';
import React from 'react';
import { EmptyEvents } from '@/components/dashboard/EmptySection/EmptyEvents.component';
import { Card } from '@components';

export const EventsSection: React.FC = () => {
  const events = [];

  return (
    <>
      <div>
        <Typography className="text-lg font-bold mb-2 text-gray-900">
          Eventos
        </Typography>
        <Typography variant="small" className="text-gray-500 font-normal">
          Crie e gerencie todos os eventos do seu ministério.
        </Typography>
      </div>

      <Card className="mt-6">
        {Boolean(events.length) ? (
          <div>Events</div>
        ) : (
          <CardBody className="px-4 py-12 w-full flex flex-col justify-center items-center">
            <EmptyEvents />
          </CardBody>
        )}
      </Card>
    </>
  );
};
