// src/api/ministry.api.ts
import api from './base.api';
import { Ministry } from '../features/ministry/ministry.interface';

const ministryApi = {
  getMany: () => api.get<Ministry[]>('/ministry'),
  createOne: (data: { name: string }) => api.post<Ministry>('/ministry', data),
};

export default ministryApi;
