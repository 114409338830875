// src/features/ministry/ministrySlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ministryApi from '../../api/ministry.api';
import { Ministry } from './ministry.interface';

interface MinistryState {
  ministries: Ministry[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: MinistryState = {
  ministries: [],
  status: 'idle',
  error: null,
};

export const fetchMinistries = createAsyncThunk(
  'ministry/fetchMinistries',
  async () => {
    const response = await ministryApi.getMany();
    return response.data;
  }
);

export const createMinistry = createAsyncThunk(
  'ministry/createMinistry',
  async (name: string) => {
    const response = await ministryApi.createOne({ name });
    return response.data;
  }
);

const ministrySlice = createSlice({
  name: 'ministry',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Ministries
      .addCase(fetchMinistries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMinistries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ministries = action.payload;
      })
      .addCase(fetchMinistries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch ministries';
      })
      // Create Ministry
      .addCase(createMinistry.fulfilled, (state, action) => {
        state.ministries.push(action.payload);
      });
  },
});

export default ministrySlice.reducer;
