import React from 'react';
import { EventsSection } from './sections/EventsSection.component';
import { BlurFade } from '@components';
import { twMerge } from 'tailwind-merge';

export const Events = () => {
  return (
    <section className={twMerge('w-full')}>
      <BlurFade isVisible delay={0.1}>
        <EventsSection />
      </BlurFade>
    </section>
  );
};
