import React from 'react';
import {
  Input as NativeInput,
  InputProps as NativeInputProps,
} from '@material-tailwind/react';

interface InputProps extends NativeInputProps {
  className?: string;
}

export const Input: React.FC<InputProps> = ({ ...props }) => (
  <NativeInput
    color="gray"
    size="lg"
    className={`w-full placeholder:opacity-100 !border-t-blue-gray-200 ${props.className && ''} ${props.error && 'error'}`}
    labelProps={{
      className: 'hidden',
      ...props.labelProps,
    }}
    containerProps={{
      className: `input-wrapper ${props.error && 'error'}`,
      ...props.containerProps,
    }}
    {...props}
  />
);
