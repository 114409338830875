// Dashboard.tsx
import React from 'react';
import { Sidebar } from './Sidebar.component';
import { NavigateWithQuery } from '@components';
import { Routes, Route } from 'react-router-dom';
import { Events } from './pages/events/Events.page';
import { Calendar } from './pages/calendar/Calendar.page';
import { Settings } from './pages/settings/Settings.page';
import { Card } from '@material-tailwind/react';
import { twMerge } from 'tailwind-merge';
import { theme } from '@constants/theme.constant';

export const Dashboard = () => {
  return (
    <div className="flex min-h-screen font-sans bg-gray-50">
      <Sidebar />

      <Card
        className={twMerge(
          'border border-gray-300 overflow-hidden shadow-sm flex-1 bg-white',
          theme.spacing.padding.main,
          theme.spacing.margin.top.small,
          theme.spacing.margin.bottom.small,
          theme.spacing.margin.right.small
        )}
      >
        <Routes>
          <Route path="events" element={<Events />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="settings" element={<Settings />} />

          <Route
            path="/"
            element={<NavigateWithQuery to="/dashboard/events" />}
          />
        </Routes>
      </Card>
    </div>
  );
};
