import React from 'react';
import {
  Button as NativeButton,
  ButtonProps,
  Spinner,
} from '@material-tailwind/react';

export const Button: React.FC<ButtonProps> = ({
  loading,
  className,
  ...props
}) => {
  return (
    <NativeButton
      {...props}
      onClick={loading ? props.onClick : undefined}
      disabled={loading}
      className={`justify-center items-center min-h-12 flex ${className}`}
    >
      {loading ? <Spinner className="h-4 w-4" /> : props.children}
    </NativeButton>
  );
};
