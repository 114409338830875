import React, { PropsWithChildren } from 'react';
import { Typography, Button } from '@material-tailwind/react';
import { EmptyEventsProps } from './EmptySection.interface';
import { theme } from '@constants/theme.constant';

export const EmptySection: React.FC<PropsWithChildren<EmptyEventsProps>> = ({
  children,
  title,
  description,
  primaryButton,
  secondaryButton,
}) => (
  <>
    {children}

    {/* Title */}
    <div className="container lg:w-4/12 text-center relative z-10 my-auto mx-auto">
      <Typography variant="h2" className="text-lg text-gray-900 font-bold">
        {title}
      </Typography>
      {description && (
        <Typography
          variant="lead"
          className="mt-2 w-full text-md text-gray-800"
        >
          {description}
        </Typography>
      )}
      <div
        className={`mt-8 flex w-full justify-center ${theme.spacing.gap.main}`}
      >
        {secondaryButton && (
          <Button onClick={secondaryButton.onClick} variant="text">
            {secondaryButton.label}
          </Button>
        )}
        {primaryButton && (
          <Button onClick={primaryButton.onClick} variant="gradient">
            {primaryButton.label}
          </Button>
        )}
      </div>
    </div>
  </>
);
