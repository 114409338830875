export const WORDS_FOR_ROTATION = [
  {
    element: (
      <div>
        sua <strong>igreja</strong>
      </div>
    ),
    position: 165,
  },
  {
    element: (
      <div>
        seus <strong>eventos</strong>
      </div>
    ),
    position: 190,
  },
  {
    element: (
      <div>
        seu <strong>ministério</strong>
      </div>
    ),
    position: 200,
  },
  {
    element: (
      <div>
        seus <strong>retiros</strong>
      </div>
    ),
    position: 180,
  },
  {
    element: (
      <div>
        sua <strong>comunidade</strong>
      </div>
    ),
    position: 220,
  },
];
