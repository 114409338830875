import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Dashboard } from '@features/dashboard/Dashboard.component';
import { NavigateWithQuery, PrivateRoute } from '@components';
import { RootState } from '@store';
import { AuthRoutes } from '@features/auth/pages/AuthRoutes.component';
import { ThemeProvider } from '@material-tailwind/react';
import { ToastContainer, Slide } from 'react-toastify';
import { useProfileSetupSideEffect, useCaptureSignup } from '@hooks/auth.hook';
import { useSelector } from '@hooks/redux.hook';

const App: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.auth);

  useProfileSetupSideEffect();
  useCaptureSignup();

  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <NavigateWithQuery to={profile ? '/dashboard' : '/login'} />
            }
          />

          <Route path="/*" element={<AuthRoutes />} />

          <Route
            path="/dashboard/*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        hideProgressBar
        limit={3}
        icon={false}
        transition={Slide}
      />
    </ThemeProvider>
  );
};

export default App;
