import React from 'react';
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/solid';

const GmailInboxIllustration = () => {
  return (
    <div className="w-full max-w-md mx-auto bg-white border border-gray-50 shadow-sm rounded-lg scale-75">
      {/* Header */}
      <div className="flex items-center justify-end px-4 py-2 bg-gray-200 rounded-t-lg">
        <div className="flex items-center space-x-2">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Email Item */}

      <div className="flex items-center px-4 py-3 border-b last:border-b-0 bg-gray-50 cursor-default">
        <div className="flex-shrink-0">
          <div className="h-8 w-8 rounded-full flex items-center justify-center text-white bg-gray-500">
            <EnvelopeIcon className="h-4 w-4" />
          </div>
        </div>
        <div className="flex-1 px-4">
          <div className="flex justify-between items-center">
            <span className="block text-gray-800 font-semibold text-black">
              Ministry Hub
            </span>
          </div>
          <span className="block text-sm text-left text-gray-600 font-semibold text-black">
            Ative sua conta
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-gray-500 text-xs">12:34 PM</span>
          <button className="text-gray-400 hover:text-gray-600 focus:outline-none">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GmailInboxIllustration;
