import React from 'react';
import {
  Menu,
  Avatar,
  Button,
  MenuList,
  MenuItem,
  Typography,
  IconButton,
  MenuHandler,
} from '@material-tailwind/react';
import {
  PlusIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { LocationMarker } from 'heroicons-react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { BlurFade, Card } from '@components';

interface EeventsCardPropsType {
  title: string;
  img: string;
  name: string;
  time: string;
  location: string;
  monday: boolean;
}

function EeventsCard({
  title,
  img,
  name,
  time,
  location,
  monday,
}: EeventsCardPropsType) {
  return (
    <Card
      shadow={false}
      className="w-full mx-auto border border-gray-300 p-4 !rounded-lg"
    >
      <div className="flex justify-between items-start">
        <div>
          <Typography
            variant="h6"
            className={`${monday ? 'text-gray-500' : '!text-gray-900'}`}
          >
            {title}
          </Typography>
          <div className="flex flex-wrap gap-x-6 gap-y-2">
            <div className="mt-2 flex items-center gap-2">
              <Avatar
                variant="rounded"
                size="xs"
                alt={name}
                className="border-2 border-white hover:z-10 focus:z-10"
                src={img}
              />
              <Typography
                variant="small"
                className={`${monday ? 'text-gray-500 font-normal' : '!text-gray-900 font-medium'}`}
              >
                {name}
              </Typography>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <ClockIcon className="h-4 w-4 text-gray-500" />
              <Typography
                variant="small"
                className={`${monday ? 'text-gray-500 font-normal' : '!text-gray-900 font-medium'}`}
              >
                {time}
              </Typography>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <LocationMarker
                className="h-4 w-4 text-gray-500"
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <Typography
                variant="small"
                className={`${monday ? 'text-gray-500 font-normal' : '!text-gray-900 font-medium'}`}
              >
                {location}
              </Typography>
            </div>
          </div>
        </div>
        <Menu>
          <MenuHandler>
            <IconButton size="sm" variant="text">
              <EllipsisVerticalIcon className="w-6 h-6 text-gray-400" />
            </IconButton>
          </MenuHandler>
          <MenuList>
            <MenuItem>Edit</MenuItem>
            <MenuItem>Update</MenuItem>
            <MenuItem>Delete</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </Card>
  );
}

const EVENTS_DATA = [
  {
    title: 'Content Strategy Workshop',
    img: 'https://www.material-tailwind.com/img/avatar6.jpg',
    name: 'Emma Roberts',
    time: '09:00 AM - 11:00 AM',
    location: 'Online',
    monday: true,
  },
  {
    title: 'UX Design Review',
    img: 'https://www.material-tailwind.com/img/avatar2.jpg',
    name: 'Lucian Eurel',
    time: '3:00 PM - 4:30 PM',
    location: 'Design Studio, Room 203',
    monday: true,
  },
  {
    title: 'New Campaign Kickoff Meeting',
    img: 'https://www.material-tailwind.com/img/avatar5.jpg',
    name: 'Misha Stam',
    time: '09:00 AM - 11:00 AM',
    location: 'Client HQ',
    monday: false,
  },
  {
    title: 'Team Goals Alignment',
    img: 'https://www.material-tailwind.com/img/avatar1.jpg',
    name: 'Marcel Glock',
    time: '1:00 PM - 2:00 PM',
    location: 'Online',
    monday: false,
  },
  {
    title: 'Product Launch Planning',
    img: 'https://www.material-tailwind.com/img/avatar4.jpg',
    name: 'Mike Devis',
    time: '3:00 PM - 4:00 PM',
    location: 'Online',
    monday: false,
  },
];

export const Calendar = () => {
  return (
    <BlurFade isVisible delay={0.1}>
      <div className="flex items-start justify-between flex-col md:flex-row">
        <div>
          <Typography className="text-lg font-bold mb-2 text-gray-900">
            Upcoming Events and Activities
          </Typography>
          <Typography variant="small" className="text-gray-500 font-normal">
            Stay on top of your schedule with our detailed events.
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <IconButton variant="outlined" className="border border-gray-200">
            <ChevronLeftIcon
              strokeWidth={3}
              className="w-3.5 h-3.5 text-gray-900"
            />
          </IconButton>
          <IconButton variant="outlined" className="border border-gray-200">
            <ChevronRightIcon
              strokeWidth={3}
              className="w-3.5 h-3.5 text-gray-900"
            />
          </IconButton>
          <Button variant="outlined" className="border border-gray-200">
            Today
          </Button>
          <IconButton variant="outlined" className="border border-gray-200">
            <PlusIcon strokeWidth={3} className="w-3.5 h-3.5 text-gray-900" />
          </IconButton>
        </div>
      </div>
      <div className="mt-6 grid md:gap-x-4 gap-y-4 items-start md:grid-cols-6 grid-cols-1">
        <Card
          shadow={false}
          className="col-span-1 w-full h-full border border-gray-300 p-4 !rounded-lg"
        >
          <Typography className="text-gray-900" variant="h6">
            Monday
          </Typography>
          <Typography
            variant="small"
            className="text-gray-500 font-normal mt-2"
          >
            Aug 29
          </Typography>
        </Card>
        <div className="space-y-4 col-span-5">
          {EVENTS_DATA.slice(0, 2).map((props, key) => (
            <EeventsCard key={key} {...props} />
          ))}
        </div>
      </div>
      <div className="mt-4 grid md:gap-x-4 gap-y-4 items-start md:grid-cols-6 grid-cols-1">
        <Card
          shadow={false}
          className="col-span-1 w-full h-full border border-gray-300 p-4 !rounded-lg"
        >
          <Typography className="text-gray-900" variant="h6">
            Tuesday
          </Typography>
          <Typography
            variant="small"
            className="text-gray-500 font-normal mt-2"
          >
            Aug 30
          </Typography>
        </Card>
        <div className="col-span-5">
          {EVENTS_DATA.slice(2, 3).map((props, key) => (
            <EeventsCard key={key} {...props} />
          ))}
        </div>
      </div>
      <div className="mt-4 grid md:gap-x-4 gap-y-4 items-start md:grid-cols-6 grid-cols-1">
        <Card
          shadow={false}
          className="col-span-1 w-full h-full border border-gray-300 p-4 !rounded-lg"
        >
          <Typography className="text-gray-900" variant="h6">
            Wednesday
          </Typography>
          <Typography
            variant="small"
            className="text-gray-500 font-normal mt-2"
          >
            Aug 31
          </Typography>
        </Card>
        <div className="col-span-5 space-y-4">
          {EVENTS_DATA.slice(3, 5).map((props, key) => (
            <EeventsCard key={key} {...props} />
          ))}
        </div>
      </div>
    </BlurFade>
  );
};
