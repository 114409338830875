// src/api/base.api.ts
import axios, { InternalAxiosRequestConfig } from 'axios';
import { store } from '../app/store';
import { RootState } from '../app/store';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001',
});

// Add a request interceptor to include the token
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const state: RootState = store.getState();
    const token = state.auth.token;

    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
