import React from 'react';
import { useSelector } from 'react-redux';
import { loginWithGoogle } from '../auth.slice';
import { RootState } from '@store';
import {
  Card,
  CardBody,
  Typography,
} from '@material-tailwind/react';
import { Input, Button } from '@components';
import { SignInMethod } from '../auth.interface';
import supabaseAuth from '@/services/supabase';
import { AuthHero } from '../components/AuthHero.component';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as validations from '@/utils/validations.util';
import google from '../../../assets/google.svg';
import { useDispatch } from '@hooks/redux.hook';
import { BlurFade } from '@/components/ui';
import { ShineBorder } from '@/components/ui/ShineBorder.component';
import GmailInboxIllustration from '@/components/ui/GmailIllustration.component';

export const Signup: React.FC = () => {
  const [awaitingConfirmation, setAwaitingConfirmation] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { status, signInMethod } = useSelector(
    (state: RootState) => state.auth
  );

  const validationSchema = Yup.object({
    name: validations.name,
    email: validations.email,
    password: validations.password,
  });

  const handleSubmit = async (values: {
    name: string;
    email: string;
    password: string;
  }) => {
    setLoading(true);
    await supabaseAuth.signUp(values.email, values.password, values.name);
    setAwaitingConfirmation(true);
  };

  const handleGoogleSignup = () => {
    dispatch(loginWithGoogle());
  };

  return (
    <section className="grid h-screen items-center lg:grid-cols-2">
      <AuthHero />
      <div className="relative my-auto p-8 text-center sm:p-10 md:p-20 xl:px-32 xl:py-24">
        <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <BlurFade isVisible={awaitingConfirmation} delay={0.2}>
            <Card className="mx-4 md:w-[26rem] border-none shadow-none">
              <ShineBorder>
                <CardBody className="flex flex-col gap-4 w-full px-4 pb-3">
                  <Typography variant="h3">Verifique seu email</Typography>
                  <Typography variant="small">
                    Você deverá receber um email com o link de confirmação em no
                    máximo 60 segundos.
                  </Typography>

                  <BlurFade isVisible={awaitingConfirmation} delay={0.3}>
                    <GmailInboxIllustration />
                  </BlurFade>
                </CardBody>
              </ShineBorder>
            </Card>
          </BlurFade>
        </div>

        <BlurFade isVisible={!awaitingConfirmation} delay={0.2}>
          <Formik
            initialValues={{ name: '', email: '', password: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="mx-auto max-w-[24rem] text-left" noValidate>
                <Button
                  color="white"
                  size="lg"
                  className="mb-8 flex h-12 items-center justify-center gap-2"
                  fullWidth
                  onClickCapture={handleGoogleSignup}
                  loading={
                    status === 'loading' && signInMethod === SignInMethod.GOOGLE
                  }
                >
                  <img src={google} alt="google" className="h-6 w-6" /> Entrar
                  com o Google
                </Button>

                <div className="my-8 flex w-full items-center gap-2">
                  <hr className="w-full bg-gray-200" />
                  <Typography color="gray" className="font-medium opacity-70">
                    ou
                  </Typography>
                  <hr className="w-full bg-blue-gray-50" />
                </div>

                <div className="mb-6">
                  <label htmlFor="name">
                    <Typography
                      variant="small"
                      className="mb-2 block font-medium text-gray-900"
                    >
                      Seu Nome
                    </Typography>
                  </label>
                  <Field name="name">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        id="name"
                        placeholder="Nome"
                        error={meta.touched && meta.error ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Typography variant="small" color="red">
                    <ErrorMessage name="name" />
                  </Typography>
                </div>

                <div className="mb-6">
                  <label htmlFor="email">
                    <Typography
                      variant="small"
                      className="mb-2 block font-medium text-gray-900"
                    >
                      Seu Email
                    </Typography>
                  </label>
                  <Field name="email">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        id="email"
                        type="email"
                        placeholder="nome@email.com"
                        error={meta.touched && meta.error ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Typography variant="small" color="red">
                    <ErrorMessage name="email" />
                  </Typography>
                </div>

                <div className="mb-6">
                  <label htmlFor="password">
                    <Typography
                      variant="small"
                      className="mb-2 block font-medium text-gray-900"
                    >
                      Senha
                    </Typography>
                  </label>
                  <Field name="password">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        id="password"
                        type="password"
                        placeholder="********"
                        error={meta.touched && meta.error ? meta.error : null}
                      />
                    )}
                  </Field>
                  <Typography variant="small" color="red">
                    <ErrorMessage name="password" />
                  </Typography>
                </div>

                <Button
                  type="submit"
                  color="gray"
                  className="mt-8"
                  size="lg"
                  fullWidth
                  loading={loading}
                  disabled={isSubmitting}
                >
                  Criar minha conta
                </Button>

                <Typography
                  color="gray"
                  className="font-medium opacity-70 mt-6 text-center"
                >
                  Já tem conta?{' '}
                  <a href="/login" className="font-medium text-gray-900">
                    Entre aqui
                  </a>
                </Typography>
              </Form>
            )}
          </Formik>
        </BlurFade>
      </div>
    </section>
  );
};
