import React from 'react';
import { BlurFade } from '@/components/ui';

export const Settings = () => {
  return (
    <BlurFade isVisible delay={0.1}>
      SETTINGS
    </BlurFade>
  );
};
