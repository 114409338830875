export const theme = {
  spacing: {
    gap: {
      main: 'gap-2',
    },
    padding: {
      main: 'p-4',
      small: 'p-2',
      top: {
        main: 'pt-4',
        small: 'pt-2',
      },
      bottom: {
        main: 'pb-4',
        small: 'pb-2',
      },
      left: {
        main: 'pl-4',
        small: 'pl-2',
      },
      right: {
        main: 'pr-4',
        small: 'pr-2',
      },
    },
    margin: {
      main: 'm-4',
      small: 'm-2',
      top: {
        main: 'mt-4',
        small: 'mt-2',
      },
      bottom: {
        main: 'mb-4',
        small: 'mb-2',
      },
      left: {
        main: 'ml-4',
        small: 'ml-2',
      },
      right: {
        main: 'mr-4',
        small: 'mr-2',
      },
    },
  },
};
